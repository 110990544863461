import React, { useEffect, useState } from 'react';
import './DetailPage.css';
import { Element } from 'react-scroll'
import CollectionsApi from '../../config/CollectionsApi';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { baseURL } from '../../config';
import { Fade } from "@material-ui/core";
import SectionTextSmall from '../../components/SectionTextSmall/index';
import Masonry from 'react-masonry-css';
import { ReactComponent as Arrow2 } from '../../images/arrow2.svg';
import { ReactComponent as ArrowBack } from '../../images/arrow-back.svg';
import * as Scroll from 'react-scroll';
import { BottomScrollListener } from 'react-bottom-scroll-listener';

const MobileView = () => {
    const [collection, setCollection] = useState({});
    const [loading, setLoading] = useState(true);
    const [checked, setChecked] = useState(false);
    const [data, setData] = useState([]);
    const [dataAll, setDataAll] = useState([]);
    const [end, setEnd] = useState(10);
    const [start, setStart] = useState(0);
    const [isCliked, setIsCliked] = useState(false);


    const handleClick = () => {
        window['scrollTo']({ top: 0, behavior: 'smooth' })
    }
    const { id } = useParams()

    useEffect(() => {
        CollectionsApi.detail(id).then(res => {
            // console.log('res', res);
            setCollection(res);
            setLoading(false);
            setChecked(true);
            const images = [
                res.picture12.url,
                res.picture1.url,
                res.picture2.url,
                res.picture3.url,
                res.picture4.url,
                res.picture5.url,
                res.picture6.url,
                res.picture7.url,
                res.picture8.url,
                res.picture9.url,
                res.picture10.url,
                res.picture11.url,
            ];
            checkData(images);
            setDataAll(res.addtionalPicture);
        })

    }, [])

    const breakpointColumnsObj = {
        default: 1,
        1100: 1,
        700: 1,
        500: 0
    };

    const scroll = Scroll.animateScroll;

    function checkData(cd) {
        cd.forEach(cek => {
            if(cek){
                data.push(cek);
            }
        });
    }

    function pushData(dataPush) {
        if(!data.includes(dataPush.url)){
            data.push(dataPush.url);
        }
    }

    if (loading) {
        return <p></p>
    }
    return (
        <Element id='our-collection' name='our-collection'>
            {
                collection ?
                    <div>
                        <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="my-masonry-grid-det"
                            columnClassName="my-masonry-grid_column-det"
                        >
                            <div>
                                <div className="style-title">
                                    <Link to={'/collections'} style={{ textDecoration: 'none' }}>
                                        <ArrowBack style={{ float: 'left' }} /> <p className="text-container back-text"> Back to All Campaigns</p>
                                    </Link>
                                    <p className={"title-container"}>{collection.bigTitle}</p>
                                    <p className={"text-container"}>{collection.description}</p>
                                </div>
                                {
                                    data.map((dataMap, i) => {
                                        return <div key={dataMap + i}>
                                            <Fade in={checked} appear>
                                                <img src={baseURL + dataMap} className="img-det-style"></img>
                                            </Fade>
                                        </div>
                                    })
                                }

                                {isCliked && (
                                    dataAll.slice(start, end).map((data2, i) => {
                                        return <div key={data2 + i}>
                                            {
                                                pushData(data2)
                                            }
                                        </div>

                                    })
                                )}
                                <BottomScrollListener onBottom={() => { setIsCliked(true); setStart(end + 1); setEnd(end + 11); 
                                    // console.log(start); console.log(end); 
                                    }} />

                                <div>
                                    <div className="vert-scale-arrow">
                                        <Arrow2 onClick={handleClick} />
                                    </div>
                                    <div className="vert-back">
                                        <SectionTextSmall value={"BACK TO TOP"}></SectionTextSmall>
                                    </div>
                                </div>
                            </div>
                        </Masonry>
                    </div >
                    :
                    ''
            }

        </Element >
    )
}

export default MobileView
