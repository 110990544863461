import React from "react";
import Question2 from '../sections/Concierge/Question'
const Question = () => {
    return (
        <div>
            <Question2></Question2>
        </div>
    );
};

export default Question;