import React, { useState } from 'react'
import NavbarRightLeft from '../NavbarRightLeft'
import Sidebar from '../Sidebar'
import './Header.css'
import useDocumentScrollThrottled from './useDocumentScrollThrottled';
import { Link } from 'react-router-dom';
import closeIcn from '../../images/close_icon.svg';
import { Grid, Fade } from "@material-ui/core";
import {
    Nav,
    NavTulisan,
    NavbarContainer,
    NavLogo,
    NavBurgerIcon,
    NavEditIcon,
    NavBurgerText,
    NavBurgerImage,
    ConciergeImage
} from "../NavbarRightLeft/NavbarElement";

const HeaderClose = () => {
    const [hamburgerOpen, setHamburgerOpen] = useState(false)

    const toggle = () => {
        setHamburgerOpen(!hamburgerOpen)
    }
    const [conciergeOpen, setConciergeOpen] = useState(false)

    const toggle2 = () => {
        setConciergeOpen(!conciergeOpen)
    }
    // const hideHeader = () => {
    //     if (window.scrollY >= currentP) {
    //         setNavbar(true);
    //         setCurr(window.scrollY);
    //     }
    //     else {
    //         setNavbar(false);
    //         setCurr(window.scrollY);
    //     }
    // };
    //window.addEventListener('scroll', hideHeader);

    const [shouldHideHeader, setShouldHideHeader] = useState(false);
    const [shouldShowShadow, setShouldShowShadow] = useState(false);

    const MINIMUM_SCROLL = 80;
    const TIMEOUT_DELAY = 400;

    useDocumentScrollThrottled(callbackData => {
        const { previousScrollTop, currentScrollTop } = callbackData;
        const isScrolledDown = previousScrollTop < currentScrollTop;
        const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

        setShouldShowShadow(currentScrollTop > 2);

        setTimeout(() => {
            setShouldHideHeader(isScrolledDown && isMinimumScrolled);
        }, TIMEOUT_DELAY);
    });

    // const shadowStyle = shouldShowShadow ? 'shadow' : '';
    const hiddenStyle = shouldHideHeader ? 'hidden' : '';

    return (
        <div className={`header`}>
            <Nav id="navbar" style={{ background: 'rgba(0, 0, 0, 0.5)', WebkitBackdropFilter: 'blur(10px)', backdropFilter: 'blur(10px)' }}>
                <Grid container spacing={2}>
                    <Grid item md={12} />
                    <Grid item md={12} />
                    <Grid item md={12} />
                    <Grid item md={11} style={{ display: 'flex', justifyContent: 'end'}}>
                        <Link to={'/collections'} style={{ textDecoration: 'none' }}>
                            <img src={closeIcn}></img>
                        </Link>
                    </Grid>
                    <Grid item md={1} />
                    <Grid item md={1} />
                    <Grid item md={10}/>
                    <Grid item md={1} />
                </Grid>
            </Nav>
        </div>
    )
}

export default HeaderClose
