import React from 'react'
import CollectionDetailPage from '../sections/CollectionsDetailPage'

const CollectionsDetail = () => {
  return (
    <div>
      <CollectionDetailPage />
    </div>
  )
}

export default CollectionsDetail;
