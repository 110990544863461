import React, { useEffect, useState, useCallback } from 'react';
import './DetailPageAll.css';
import { Element } from 'react-scroll'
import CollectionsApi from '../../config/CollectionsApi';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { baseURL } from '../../config';
import { Grid, Fade } from "@material-ui/core";
import SectionTextSmall from '../../components/SectionTextSmall/index';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Lightbox from 'react-image-lightbox';
import "react-image-lightbox/style.css";
import Arrow from '../../images/arrow.png';
import { ReactComponent as ArrowBack } from '../../images/arrow-back.svg';
import closeIcn from '../../images/close_icon.svg';
import Masonry from 'react-masonry-css'
import LazyLoad from 'react-lazyload';
import { BottomScrollListener } from 'react-bottom-scroll-listener';
import { Container, Button } from 'react-floating-action-button'

const CollectionsAll = () => {
    const [collection, setCollection] = useState({});
    const [data, setData] = useState([]);
    const [dataAll, setDataAll] = useState([]);
    const [loading, setLoading] = useState(true);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [end, setEnd] = useState(10);
    const [start, setStart] = useState(0);
    const [temp, setTemp] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [isCliked, setIsCliked] = useState(false);
    const [checked, setChecked] = useState(false);

    const { id } = useParams()

    const breakpointColumnsObj = {
        default: 3,
        1100: 3,
        700: 3,
        500: 3
    };

    useEffect(() => {
        CollectionsApi.detail(id).then(res => {
            setCollection(res);
            setLoading(false);
            setChecked(true);
            const images = [
                res.picture1.url,
                res.picture2.url,
                res.picture3.url,
                res.picture4.url,
                res.picture5.url,
                res.picture6.url,
                res.picture7.url,
                res.picture8.url,
                res.picture9.url,
                res.picture10.url,
                res.picture11.url,
                res.picture12.url,
            ];
            let tempImages = [];
            images.forEach(q => {
                if(!!q){
                    tempImages.push(q);
                }else{
                    tempImages.push("");
                }
            });
            checkData(tempImages);
            setDataAll(res.addtionalPicture);
        })

    }, [])

    function refreshPage() {
        window.location.reload();
    }

    function checkData(cd) {
        cd.forEach(cek => {
            if(cek){
                data.push(cek);
            }
        });
    }

    function pushData(dataPush) {
        if (!data.includes(dataPush.url)) {
            data.push(dataPush.url);
        }
    }

    if (loading) {
        return <p></p>
    }
    return (
        <Element id='collections-all' name='collections-all'>

            {
                collection ?
   
                    
                    <div className='page-container-collectionAll'>

                        <TableCell align="left" style={{ borderBottom: "none" }}>
                            <Grid container spacing={3}>
                                <Grid item md={12}>
                                    <div className="style-title">
                                        <Link to={`/collections/${id}`} style={{ textDecoration: 'none' }}>
                                            <ArrowBack style={{ float: 'left' }} /> <p className="text-container back-text"> Back to Gallery View</p>
                                        </Link>
                                        <br />
                                        <p className={"title-container"} style={{ marginTop: 15 }}>{collection.bigTitle}</p>
                                        <br />
                                        <br />
                                        <p className={"text-container"}>{collection.description}</p>
                                    </div>
                                </Grid>
                            </Grid>
                        </TableCell>
                        <br />
                        <br />
                        <Masonry
                            breakpointCols={breakpointColumnsObj}
                            className="my-masonry-grid"
                            columnClassName="my-masonry-grid_column"
                        >
                            {
                                data.map((dataMap, i) => {
                                    return <React.Fragment key={dataMap + i}>
                                        <div>
                                            <img src={baseURL + dataMap} className="imgSize" onClick={() => { setIsOpen(true); setPhotoIndex(i); }}></img>
                                            {isOpen && (
                                                <Lightbox
                                                    mainSrc={baseURL + data[photoIndex]}
                                                    // nextSrc={baseURL + data[(photoIndex + 1) % data.length]}
                                                    // prevSrc={baseURL + data[(photoIndex + data.length - 1) % data.length]}
                                                    onCloseRequest={() => setIsOpen(false)}
                                                    onMovePrevRequest={() =>
                                                        setPhotoIndex(photoIndex - 1)
                                                    }
                                                    onMoveNextRequest={() =>
                                                        setPhotoIndex(photoIndex + 1)
                                                    }
                                                />
                                            )}
                                        </div>
                                    </React.Fragment>
                                })
                            }

                            {isCliked && (
                                dataAll.slice(start, end).map((data2, i) => {
                                    return <div key={data2 + i}>
                                        {
                                            pushData(data2)
                                        }

                                    </div>

                                })
                            )}
                        </Masonry>
                        <BottomScrollListener onBottom={() => { setIsCliked(true); setStart(end + 1); setEnd(end + 11); }} />

                    </div>

              
                    :
                    ''
            }

        </Element>
    )
}

export default CollectionsAll