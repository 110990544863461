import React from "react";
import Dashboard from '../sections/Concierge/Dashboard'
const Concierge = () => {
  return (
    <div>
      <Dashboard></Dashboard>
    </div>
  );
};

export default Concierge;
