import React from "react";
import Thanks from '../sections/Concierge/Thanks'
const Thank = () => {
    return (
        <div>
            <Thanks></Thanks>
        </div>
    );
};

export default Thank;